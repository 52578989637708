import styled, { css } from 'styled-components';
import { media, colors } from '@bitcoin-portal/bitcoincom-pkg-theme';
import ContentBlock from '@bitcoin-portal/bitcoincom-pkg-components/dist/ContentBlock';

export const Wrapper = styled.div`
  h2 {
    scroll-margin-top: 90px;
    font-size: 32px;
    line-height: 40px;
  }

  h3 {
    font-size: 24px;
    line-height: 30px;
  }

  h4 {
    font-size: 20px;
    line-height: 26px;
  }

  p,
  li,
  tr {
    font-size: 18px;
    line-height: 32px;
    color: ${colors.solid.textTwo};
  }

  ${({ theme }) => css`
    width: 100%;
    position: relative;

    & > section {
      align-items: flex-start;
      padding-top: ${theme.spacing.unit * 4}px;
      padding-bottom: ${theme.spacing.unit * 4}px;
    }

    ${media.md`
    & > section {
      padding-top: ${theme.spacing.unit * 8}px;
      padding-bottom: ${theme.spacing.unit * 8}px;
    }
  `}
  `}
`;

export const StyledContentBlock = styled(ContentBlock)`
  padding: inherit;

  ${media.xl`
    padding: 0 100px 0 0;
  `}

  & > :last-child {
    display: none;

    ${media.xl`
      display: flex;
      align-self: stretch;
      min-width: 250px;
      flex-basis: 42%;
      background-color: white;
      padding-left: 100px;
      padding-bottom: 80px;

      & > div {
        margin-right: 0;
        margin-top: 48px;
      }

      & > div > div {
        /* max-width: unset; */
      }

      span {
        line-height: 40px;
      }
    `}
  }

  & > :first-child {
    flex-basis: 100%;
    text-align: left;
    overflow: auto;

    ${media.xl`
      flex-basis: 58%;
    `}
  }
`;

export const ContentWrapper = styled.div`
  .fact-wrapper {
    display: flex;
    flex-flow: column nowrap;
    :not(:first-child) {
      margin-top: 20px;
    }
  }

  ${media.xl`
    margin-left: 45px;
    max-width: 650px;
    margin-top: 80px;

    .fact-wrapper {
      flex-flow: row nowrap;
    }

    img {
      margin-right: 30px;
    }
  `}
`;
