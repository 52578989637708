/* eslint-disable no-nested-ternary */
import styled, { css } from 'styled-components';
import { media, colors } from '@bitcoin-portal/bitcoincom-pkg-theme';

export const Wrapper = styled.div`
  overflow: hidden;
  flex-shrink: 0;
  display: none;
  margin-right: ${({ theme }) => theme.spacing.unit * 8}px;
  height: 100%;
  width: 100%;

  ${media.md`
    display: block;
  `}

  ${({ bottom = false }) => {
    if (bottom === false) return '';
    return css`
      align-items: flex-end;

      ${media.md`
        display: flex;
      `}
    `;
  }};
`;

export const Item = styled.span`
  display: block;
  cursor: pointer;
  ${({ selected, games }) =>
    selected && games === 'red'
      ? css`
          color: ${colors.solid.primaryRed};
        `
      : selected && games === 'green'
      ? css`
          color: ${colors.solid.primaryGreen};
        `
      : selected && games === 'yellow'
      ? css`
          color: ${colors.solid.primaryYellow};
        `
      : selected && games === 'black'
      ? css`
          color: ${colors.solid.textOne};
        `
      : selected
      ? css`
          color: ${colors.solid.primaryBlue};
        `
      : css`
          color: ${colors.solid.textFour};
        `}

  font-weight: ${({ selected = false }) => (selected ? 800 : 600)};
  text-decoration: none;
  padding: ${({ theme }) => theme.spacing.unit}px 0;
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: 15px;

  &:first-child {
    padding-top: 0;
  }

  &:last-child {
    padding-bottom: 0;
  }
`;

export const SideBar = styled.div`
  padding-left: ${({ theme }) => theme.spacing.unit * 2}px;
  box-sizing: border-box;
  margin-top: ${({ theme }) => theme.spacing.unit * 4}px;
  padding-right: ${({ theme }) => theme.spacing.unit * 4}px;
  margin-bottom: ${({ theme }) => theme.spacing.unit * 4}px;
  overflow: hidden;
  max-width: 220px;
  position: relative;
  text-align: left;
  font-weight: normal;
  font-size: 20px;
  color: #2d323b;
  letter-spacing: 0;

  ${media.xl`
    max-width: 300px;
  `}

  & :after {
    content: '';
    position: absolute;
    ${props =>
      props.games === 'red'
        ? css`
            background-color: ${colors.solid.primaryRed};
          `
        : props.games === 'green'
        ? css`
            background-color: ${colors.solid.primaryGreen};
          `
        : props.games === 'yellow'
        ? css`
            background-color: ${colors.solid.primaryYellow};
          `
        : props.games === 'black'
        ? css`
            background-color: ${colors.solid.textOne};
          `
        : css`
            background-color: ${colors.solid.primaryBlue};
          `}

    height: 100%;
    top: 0;
    left: 0;
    width: 2px;
  }
  ${({ sticky = false }) => {
    if (sticky === false) return '';
    return css`
      position: fixed;
      top: 72px;
    `;
  }};
`;
