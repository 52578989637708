import React, { useState } from 'react';
import Button from '@bitcoin-portal/bitcoincom-pkg-components/dist/Button';
import H1 from '@bitcoin-portal/bitcoincom-pkg-components/dist/H1';
import Paragraph from '@bitcoin-portal/bitcoincom-pkg-components/dist/Paragraph';
import { StyledContentBlock, Subheader } from './styled';

const Header = props => {
  const {
    header,
  } = props;

  const [showReadMore, setShowReadMore] = useState();

  return (
    <StyledContentBlock>
      <H1>
        { header.title }
      </H1>
      <Subheader>
        <Paragraph>
          { header.text1 }
        </Paragraph>
        {showReadMore && (
          <Paragraph>
            { header.text2 }
          </Paragraph>
        )}
      </Subheader>
      <Button onClick={() => setShowReadMore(!showReadMore)}>
        {showReadMore ? (
          <span>{ header.less }</span>
        ) : (
          <span>{ header.more }</span>
        )}
      </Button>
    </StyledContentBlock>
  );
};

export default Header;
