import styled from 'styled-components';
import { media } from '@bitcoin-portal/bitcoincom-pkg-theme';
import Grid from '@bitcoin-portal/bitcoincom-pkg-components/dist/Grid';
import Image from '@bitcoin-portal/bitcoincom-pkg-components/dist/Image';
import Section from '@bitcoin-portal/bitcoincom-pkg-components/dist/Section';

export const Wrapper = styled(Section)`
  max-width: 800px;
  margin: auto;
`;

export const ReviewSection = styled(Section)`
  scroll-margin-top: 72px;
`;

export const LogoWrapper = styled.div`
  height: 80px;
`;

export const Logo = styled(Image)`
  max-height: 80px;
`;

export const Header = styled(Grid)`
  row-gap: 50px;
  & > h5 {
    display: none;
  }
  & > * {
    place-self: center;
  }
  ${media.md`
    & > h5 {
      display: block;
      font-weight: 700;
      font-size: 48px;
    }
  `}
`;

export const ScreenshotWrapper = styled.div`
  position: relative;
  margin-top: 40px;
  margin-bottom: 48px;
  width: 100%;
  padding-bottom: 47.36%;
  overflow: hidden;

  & > span {
    display: inline-block;
    position: absolute;
    left: 0;
    top: 0;
    height: 100% !important;
    width: 100% !important;
  }
`;

export const Screenshot = styled(Image)`
  height: 100%;
  max-width: 100%;
  max-height: 100%;
  object-fit: scale-down;
`;

export const FeatureWrapper = styled(Grid)`
  margin-top: 80px;
  row-gap: 20px;
`;

export const Feature = styled(Grid)`
  row-gap: 20px;
  & > * {
    place-self: start;
  }
  ${media.md`
    grid-template-columns: auto 1fr;
  `}
`;

export const FeatureImageWrapper = styled.div`
  height: 24px;
`;

export const FeatureText = styled.div`
  & h5,
  p {
    font-size: 18px;
  }
  & h5 {
    font-weight: 700;
    padding-bottom: 10px;
  }
`;
