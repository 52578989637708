import { createGlobalStyle } from 'styled-components';
import { colors } from '@bitcoin-portal/bitcoincom-pkg-theme';

export const GlobalStyle = createGlobalStyle`
  html {
    scroll-behavior: smooth;
  }

  html,
  body,
  ___gatsby,
  ___gatsby > div {
    font-family: ${({ theme }) => theme.typography.fontFamily}, sans-serif;
    margin: 0;
    padding: 0;
    color: ${({ theme }) => theme.palette.text.default};
    font-size: 14px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow-x: hidden;
    background-color: ${colors.solid.bgGrey};
    @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}px) {
      font-size: 20px;
    }

  }

  a {
    color: rgb(39, 147, 255);
    text-decoration: none;
    font-weight: 600;
  }

`;
