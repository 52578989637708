import React from 'react';

import { colors } from '@bitcoin-portal/bitcoincom-pkg-theme';
import Button from '@bitcoin-portal/bitcoincom-pkg-components/dist/Button';
import H6 from '@bitcoin-portal/bitcoincom-pkg-components/dist/H6';
import Paragraph from '@bitcoin-portal/bitcoincom-pkg-components/dist/Paragraph';
import Card from '@bitcoin-portal/bitcoincom-pkg-components/dist/Card';
import Image from '@bitcoin-portal/bitcoincom-pkg-components/dist/Image';

import {
  StyledGrid,
  CardContent,
  ImagesGrid,
  CurrenciesGrid,
  CasinoBonus,
  ButtonWrapper,
  Logo,
} from './styled';

const Casinos = props => {
  const {
    casinos,
  } = props;

  return (
    <StyledGrid item={1}>
      {casinos.casinos.map(
        ({
          id,
          logo,
          currencies = [],
          additional,
          text,
          subtext = [],
          link,
        }) => {
          return (
            <Card key={id} style={{ padding: 10 }}>
              <CardContent columns={3} columnsSm={1} columnsMd={1}>
                <ImagesGrid columns={2} columnsMd={1} columnsSm={1}>
                  <Logo>
                    <Image
                      src={logo?.source}
                      alt={logo?.alt}
                      style={{ height: 40, width: 'auto' }}
                    />
                  </Logo>
                  <CurrenciesGrid>
                    {currencies.map((currency, index) => (
                      <Image
                        key={`${currency}-currency`}
                        src={currency}
                        style={{
                          height: 32,
                          position: 'relative',
                          left: -5 * index,
                        }}
                      />
                    ))}
                    {additional > 0 && <></>}
                  </CurrenciesGrid>
                </ImagesGrid>
                <CasinoBonus columns={2} columnsMd={1} columnsSm={1}>
                  <H6>{text}</H6>
                  {subtext.length > 0 && (
                    <Paragraph style={{ color: colors.solid.textTwo }}>
                      &nbsp;{subtext}
                    </Paragraph>
                  )}
                </CasinoBonus>
                <ButtonWrapper columns={2} columnsMd={1} columnsSm={1}>
                  <Button size="small" href={`#${id}-review`}>
                    { casinos.read }
                  </Button>
                  <Button
                    size="small"
                    style={{ width: '100%' }}
                    design="primary"
                    href={link}
                    rel="noopenner noreferrer nofollow"
                    newTab
                  >
                    { casinos.visit }
                  </Button>
                </ButtonWrapper>
              </CardContent>
            </Card>
          );
        },
      )}
    </StyledGrid>
  );
};

export default Casinos;
