import styled from 'styled-components';
import Grid from '@bitcoin-portal/bitcoincom-pkg-components/dist/Grid';
import { media } from '@bitcoin-portal/bitcoincom-pkg-theme';

export const StyledGrid = styled(Grid)`
  row-gap: 12px;
`;

export const CardContent = styled(Grid)`
  & > * {
    place-self: center;
  }
  padding: 16px;
  column-gap: 0px;
  row-gap: 20px;
`;

export const ImagesGrid = styled(Grid)`
  column-gap: 0px;
  width: 100%;
  & > * {
    place-self: center;
  }
  ${media.lg`
    & > * {
      place-self: start;
    }
  `}
`;

export const Logo = styled.div`
  height: 45px;
  display: flex;
  align-items: center;
`;

export const LogoName = styled.span`
  margin-left: 10px;
  padding: 0;
  font-size: 28px;
  line-height: 32px;
  color: rgb(45, 50, 59);
  font-weight: 600;

  ${media.md`
    line-height: 32px;
    font-size: 18px;
  `}
`;

export const CurrenciesGrid = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  ${media.lg`
    justify-content: flex-start;
  `}
`;

export const CasinoBonus = styled(Grid)`
  column-gap: 0px;
  row-gap: 0px;
  place-self: center;
  width: 100%;
  text-align: center;
  background-color: rgba(246, 251, 251, 1);
  padding: 12px 20px;
  border-radius: 10px;
  & > * {
    padding-bottom: 0px;
    line-height: 32px;
    font-size: 16px;
  }
  ${media.xl`
    text-align: inherit;
    grid-template-columns: auto 1fr;
  `}
`;

export const ButtonWrapper = styled(Grid)`
  column-gap: 16px;
  row-gap: 0px;
  place-self: end;
  width: 100%;
  & > * {
    place-self: center;
    max-width: inherit;
  }
  ${media.xl`
    width: auto;
  `}
`;
