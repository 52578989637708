import React, { useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import Markdown from '@bitcoin-portal/bitcoincom-pkg-components/dist/Markdown';
import Section from '@bitcoin-portal/bitcoincom-pkg-components/dist/Section';
import { Wrapper, StyledContentBlock, ContentWrapper } from './styled';
import SideMenu from '../../Components/SideMenu';

const FaqSection = ({ htmlAst, headings }) => {

  const contentRef = useRef();

  const menuItems = useMemo(
    () =>
      (headings || [])
        .filter(h => h.depth === 2)
        .reduce((prev, curr, i) => {
          const newItem = {
            name: curr.value,
            link: `#${i + 1}`,
            hash: `#${i + 1}`,
          };
          return [...prev, newItem];
        }, []),
    [headings],
  );

  const refinedHtmlAst = useMemo(() => {
    let id = 1;
    const htmlChildren = htmlAst.children.reduce((prev, curr) => {
      if (curr.tagName === 'h2') {
        const updated = { ...curr, properties: { id: `${id}` } };
        id += 1;
        return [...prev, updated];
      }

      return [...prev, curr];
    }, []);

    return { ...htmlAst, children: htmlChildren };
  }, [htmlAst]);

  if (menuItems.length === 0) {
    return null;
  }

  return (
    <>
      <Section>
        <Wrapper>
          <StyledContentBlock
            flip={StyledContentBlock.Flips.Horizontal}
            aside={
              <SideMenu
                items={menuItems}
                contentRef={contentRef}
                games="black"
              />
            }
          >
            <ContentWrapper>
              <Markdown ref={contentRef} htmlAst={refinedHtmlAst} />
            </ContentWrapper>
          </StyledContentBlock>
        </Wrapper>
      </Section>
    </>
  );
};

FaqSection.propTypes = {
  htmlAst: PropTypes.object.isRequired,
  headings: PropTypes.arrayOf(PropTypes.shape).isRequired,
};

export default FaqSection;
