/* eslint-disable react/destructuring-assignment */
import React from 'react';
import PropTypes from 'prop-types';
import styled, { ThemeProvider } from 'styled-components';
import { IntlProvider } from 'react-intl';

import themes from '@bitcoin-portal/bitcoincom-pkg-theme';
import Nav from '@bitcoin-portal/navbar';
import Footer from '@bitcoin-portal/footer';
import ContentBlock from '@bitcoin-portal/bitcoincom-pkg-components/dist/ContentBlock';
import BuyWidgetV2 from '@bitcoin-portal/bitcoincom-pkg-components/dist/BuyWidgetV2';
import Banner from '@bitcoin-portal/bitcoincom-pkg-components/dist/Banner';

import { GlobalStyle } from '../../Components/Layout/styled';
import { DEFAULT_LOCALE } from '../../i18n';

import Header from '../Header';
// import GamblingContent from '../GamblingContent';
import Casinos from '../Casinos';
import Reviews from '../Reviews';
import Faq from '../Faq';
// import Conclusion from '../Conclusion';

const providerTheme = themes.light;

const StyledContentBlock = styled(ContentBlock)``;

const CenteredContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledBuyWidgetV2 = ({ locale = 'en' }) => (
  <CenteredContainer>
    <BuyWidgetV2
      initialCrypto="BTC"
      selectedTab="buy"
      passThroughAddress
      locale={locale}
      callback={({ data }) => {
        const params = new URLSearchParams({
          ...(data.crypto && { crypto: data.crypto }),
          ...(data.fiat && { fiat: data.fiat }),
          ...(data.amount && { amount: data.amount }),
          ...(data.address && { address: data.address }),
          ...{ utm_source: 'bitcoin_www_gambling' },
          ...{ utm_medium: 'medium_www_gambling' },
          ...{ utm_campaign: 'campaign_www_gambling' },
        });
        if (parseFloat(data.amount) > 4999 && data.fiat === 'USD') {
          window.location.href =
            'https://branch.wallet.bitcoin.com/e/buy-widget-gambling-25k';
        } else {
          window.location.href = `https://branch.wallet.bitcoin.com/buy-widget-gambling?${params.toString()}`;
        }
      }}
    />
  </CenteredContainer>
);

const Template = props => {
  const {
    pageContext: { locale, htmlAst, headings },
    header,
    casinos,
    reviews,
  } = props;

  // remove any preceding and trailing slashes (/)

  return (
    <IntlProvider
      locale={locale}
      defaultLocale={DEFAULT_LOCALE}
      // messages={messages}
    >
      <ThemeProvider theme={providerTheme}>
        <>
          <GlobalStyle />
          <Nav locale={locale} buttons={[]} links={[]} />
          <StyledContentBlock>
            <Header header={header} />
            <Casinos casinos={casinos} />
            <Reviews reviews={reviews} />

            {/* <GamblingContent content={[]} /> */}
            <StyledBuyWidgetV2 locale={locale} />
            <Banner
              title="Need a Site Review?"
              subtitle="We'd love to review your site and put it up here."
              cta={{
                link: 'mailto:affiliates@bitcoin.com?subject=Directory Review Request',
                text: 'Get in touch, now!',
                newTab: true,
              }}
            />
            <Faq headings={headings} htmlAst={htmlAst} />

            {/* <Conclusion /> */}
          </StyledContentBlock>
          <Footer locale={locale} />
        </>
      </ThemeProvider>
    </IntlProvider>
  );
};

StyledBuyWidgetV2.propTypes = {
  locale: PropTypes.string,
};

StyledBuyWidgetV2.defaultProps = {
  locale: 'en',
};

Template.propTypes = {
  pageContext: PropTypes.shape({
    supportedLocales: PropTypes.arrayOf(PropTypes.string),
    locale: PropTypes.string,
    messages: PropTypes.object,
    related: PropTypes.arrayOf(PropTypes.string),
    htmlAst: PropTypes.object,
    headings: PropTypes.arrayOf(PropTypes.object),
  }).isRequired,
  header: PropTypes.object,
  casinos: PropTypes.object,
  reviews: PropTypes.object,
};

export default Template;
