import styled from 'styled-components';
import { colors, media } from '@bitcoin-portal/bitcoincom-pkg-theme';
import ContentBlock from '@bitcoin-portal/bitcoincom-pkg-components/dist/ContentBlock';

export const StyledContentBlock = styled(ContentBlock)`
  text-align: center;
  padding: 56px 30px;

  ${media.md`
    padding: 64px 100px;
  `}
`;

export const Subheader = styled.div`
  & > p {
    font-size: 16px;
    line-height: 32px;
    color: ${colors.solid.textTwo};
  }

  ${media.md`

    & > p {
      display: flex;
      color: ${colors.solid.textTwo};
      font-size: 18px;
      line-height: 32px;
    }
  `}
`;

export const ButtonWrapper = styled.div`
  margin-top: 32px;

  ${media.md`
    margin-top: 16px;
  `}
`;
