import React from 'react';

import Button from '@bitcoin-portal/bitcoincom-pkg-components/dist/Button';
import H2 from '@bitcoin-portal/bitcoincom-pkg-components/dist/H2';
import H6 from '@bitcoin-portal/bitcoincom-pkg-components/dist/H6';
import Paragraph from '@bitcoin-portal/bitcoincom-pkg-components/dist/Paragraph';
import Image from '@bitcoin-portal/bitcoincom-pkg-components/dist/Image';
import List from '@bitcoin-portal/bitcoincom-pkg-components/dist/List';

import {
  Wrapper,
  Logo,
  Header,
  Screenshot,
  FeatureWrapper,
  Feature,
  FeatureText,
  ScreenshotWrapper,
  LogoWrapper,
  ReviewSection,
  FeatureImageWrapper,
} from './styled';

const Reviews = props => {
  const {
    reviews,
  } = props;

  return (
    <Wrapper>
      <H2 style={{ textAlign: 'center' }}>
        { reviews.title }
      </H2>
      {reviews.casinos.map(({ id, logo, title, link, review }) => {
        const description = review?.description || '';
        const perks = review?.perks || [];
        const features = review?.features || [];

        return (
          <ReviewSection key={`${id}-review`} id={`${id}-review`}>
            <Header columns={1} columnsSm={1} columnsMd={1}>
              <LogoWrapper>
                <Logo src={logo?.source} alt={logo?.alt} />
              </LogoWrapper>
              <H6 id={`${id}-review-scroll`}>{title}</H6>
            </Header>
            {review?.image ? (
              <ScreenshotWrapper>
                <Screenshot src={review.image.source} alt={review.image.alt} />
              </ScreenshotWrapper>
            ) : (
              <div style={{ height: 40 }} />
            )}
            {description.map(paragraph => (
              // eslint-disable-next-line
              <Paragraph
                key={`${paragraph}-paragraph`}
                dangerouslySetInnerHTML={{ __html: paragraph }}
              />
            ))}
            <List style={{ fontWeight: 700, fontSize: 18, marginTop: 64 }}>
              {perks.map(perk => (
                <List.Item key={`${perk}-perk`}>{perk}</List.Item>
              ))}
            </List>
            <FeatureWrapper columns={2} columnsMd={2} columnsSm={1}>
              {features.map(feature => (
                <Feature
                  columns={2}
                  columnsMd={2}
                  columnsSm={1}
                  key={feature.title}
                >
                  <FeatureImageWrapper>
                    <Image
                      src={feature?.image?.source}
                      alt={feature?.image?.alt}
                    />
                  </FeatureImageWrapper>
                  <FeatureText>
                    <H6>{feature?.title}</H6>
                    <Paragraph>{feature?.text}</Paragraph>
                  </FeatureText>
                </Feature>
              ))}
            </FeatureWrapper>
            <div style={{ textAlign: 'center', width: '100%' }}>
              <Button
                size="small"
                design="primary"
                rel="noopenner noreferrer nofollow"
                href={link}
                newTab
              >
                { reviews.play }
              </Button>
            </div>
          </ReviewSection>
        );
      })}
    </Wrapper>
  );
};

export default Reviews;
