import React, { useMemo, createElement, Fragment } from 'react';

import ReactDOM from 'react-dom/client';
import './index.css';

import { unified } from 'unified';
import parse from 'remark-parse';
import remark2react from 'remark-react';
import { toHast } from 'mdast-util-to-hast';

import Template from './Gambling/Template/Template';
import reportWebVitals from './reportWebVitals';
import { DEFAULT_LOCALE } from './i18n';
import { header, casinos, reviews, faqs } from './i18n/data';

const root = ReactDOM.createRoot(document.getElementById('root'));

const App = () => {
  const locale = DEFAULT_LOCALE;

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const htmlAst = useMemo(() => {
    const html = unified()
      .use(parse)
      .use(remark2react, { createElement, Fragment })
      .parse(faqs);

    return toHast(html);
  }, []);

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const headings = useMemo(() => {
    return htmlAst.children.reduce(
      (prev, curr) =>
        curr.tagName === 'h2'
          ? [...prev, { value: curr.children[0].value, depth: 2 }]
          : prev,
      [],
    );
  }, [htmlAst.children]);

  return(
    <React.StrictMode>
      <Template 
        pageContext={{
          locale,
          htmlAst,
          headings,
        }}
        header={header}
        casinos={casinos}
        reviews={reviews}
      />
    </React.StrictMode>
  );
};

root.render(<App />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
